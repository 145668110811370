(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('FeedbackCtrl', [
        '$scope',
        '$preloaded',
        'SalePointsHelper',
        'FeedbackModal',
        function ($scope, $preloaded, salePointsHelper, modal) {
            $scope.showModal = modal.activate;
            $scope.salePoints = $preloaded.get('salePoints');
            $scope.geoObjects = salePointsHelper.getGeoObjects($scope.salePoints);
            $scope.geoObjects.balloonOpen = salePointsHelper.balloonOpenFabric($scope.geoObjects);
            $scope.changeCurrent = function (object) {
                $scope.currentObject = $scope.geoObjects.balloonOpen(object);
            };
            $scope.changeCurrent(_.first($scope.geoObjects));
        }]);
})(window, window.app, window._, window.debug);
